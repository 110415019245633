<template>
   <section class="cont">
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>数据中台</el-breadcrumb-item>
            <el-breadcrumb-item>场景+</el-breadcrumb-item>
            <el-breadcrumb-item>组合画像</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <el-row class="top">
         <el-col :span="12" class="common-box left-box">
            <el-row class="chart-box">
               <el-row class="title">客人年龄分布</el-row>
               <el-row class="chart-cont">
                  <el-row class="chart" id="age-pie"></el-row>
               </el-row>
            </el-row>
         </el-col>
         <el-col :span="12" class="common-box right-box">
            <el-row class="chart-box">
               <el-row class="title">控制方式占比图</el-row>
               <el-row class="chart-cont">
                  <el-row class="chart" id="like-pie"></el-row>
               </el-row>
            </el-row>
         </el-col>
      </el-row>
      <el-row class="top">
         <el-col :span="12" class="common-box left-box">
            <el-row class="chart-box">
               <el-row class="title">智控设备使用占比</el-row>
               <el-row class="chart-cont">
                  <el-row class="chart" id="like-bar"></el-row>
               </el-row>
            </el-row>
         </el-col>
         <el-col :span="12" class="common-box right-box">
            <el-row class="chart-box">
               <el-row class="title">购物爱好</el-row>
               <el-row class="chart-cont">
                  <el-row class="chart" id="shop-like-bar"></el-row>
               </el-row>
            </el-row>
         </el-col>
      </el-row>
   </section>
</template>

<script>
   import { urlObj } from '@/api/interface'
   import { mapState } from 'vuex'
   export default {
      data() {
         return{
            ageGroup: [
               {value: 'young', label: '青年人'},
               {value: 'middle', label: '中年人'},
               {value: 'old', label: '老年人'},
               {value: 'unknown', label: '未知'}
            ],       // 年龄段
            ageLevel: 'young',
            mobile: '',         // 手机号
            boxIcon: true,      // 侧边栏折叠状态
            tableLoad: true,    //
            hotelId: '',
            index: 1,
            pieOptions: {
               tooltip: {
                  trigger: 'item',
                  formatter: '{a} <br/>{b} : {c} ({d}%)'
               },
               legend: {
                  orient: 'vertical',
                  left: 10,
                  top: 40,
                  data: ['青年人', '中年人', '老年人']
               },
               color:['#ffa333', '#3edbb7', '#0272ff'],
               series: [
                  {
                     name: '控制来源',
                     type: 'pie',
                     radius: '55%',
                     center: ['50%', '60%'],
                     data: [
                        {value: 150, name: '老年人'},
                        {value: 1000, name: '年轻人'},
                        {value: 600, name: '中年人'},
                     ],
                     emphasis: {
                        itemStyle: {
                           shadowBlur: 10,
                           shadowOffsetX: 0,
                           shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                     }
                  }
               ]
            },
            pieOptions1: {
               tooltip: {
                  trigger: 'item',
                  formatter: '{a} <br/>{b} : {c} ({d}%)'
               },
               legend: {
                  orient: 'vertical',
                  left: 10,
                  top: 40,
                  data: ['按键控制', '智能语音', '小程序客控']
               },
               color:['#ffa333', '#3edbb7', '#0272ff'],
               series: [
                  {
                     name: '控制方式',
                     type: 'pie',
                     radius: '55%',
                     center: ['50%', '60%'],
                     data: [
                        {value: 510, name: '按键控制'},
                        {value: 430, name: '智能语音'},
                        {value: 260, name: '小程序客控'},
                     ],
                     emphasis: {
                        itemStyle: {
                           shadowBlur: 10,
                           shadowOffsetX: 0,
                           shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                     }
                  }
               ]
            },
            barOptions: {
               tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                     type: 'shadow'
                  }
               },
               color: ['#0272ff'],
               grid: {
                  left: '3%',
                  right: '4%',
                  bottom: '3%',
                  containLabel: true
               },
               xAxis: {},
               yAxis: {
                  type: 'category',
                  data: []
               },
               series: [
                  {
                     name: '智控设备',
                     type: 'bar',
                     barWidth: '60%',
                     data: [],
                     itemStyle: {
                        normal: {
                           label: {
                              show: true,		  //开启显示
                              position: 'right',	//在上方显示
                              textStyle: {	    //数值样式
                                 color: 'black',
                                 fontSize: 14
                              }
                           }
                        }
                     }
                  }
               ]
            },
            barOptions1: {
               tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                     type: 'shadow'
                  }
               },
               color: ['#0272ff'],
               grid: {
                  left: '3%',
                  right: '4%',
                  bottom: '3%',
                  containLabel: true
               },
               xAxis: {},
               yAxis: {
                  type: 'category',
                  data: []
               },
               series: [
                  {
                     name: '购物爱好',
                     type: 'bar',
                     barWidth: '60%',
                     data: [],
                     itemStyle: {
                        normal: {
                           label: {
                              show: true,		     // 开启显示
                              position: 'right',  // 在上方显示
                              textStyle: {	     // 数值样式
                                 color: 'black',
                                 fontSize: 14
                              }
                           }
                        }
                     }
                  }
               ]
            },
         }
      },
      computed: {
         ...mapState(['hotelInfo'])
      },
      mounted() {
         this.hotelId = this.hotelInfo.id
         this.getCombinationPortrait()
         let agepie =  this.$echarts.init(document.getElementById('age-pie'))
         let likepie = this.$echarts.init(document.getElementById('like-pie'))
         let likebar = this.$echarts.init(document.getElementById('like-bar'))
         let shoplikebar = this.$echarts.init(document.getElementById('shop-like-bar'))
         window.onresize=function(){
            agepie.resize()
            likepie.resize()
            likebar.resize()
            shoplikebar.resize()
         }
      },
      methods: {
         // 获取组合画像数据
         getCombinationPortrait(){
            const url = urlObj.combinationPortrait + `?ageLevel=${this.ageLevel}&hotelId=${this.hotelInfo.id}`
            this.$axios.post(url, {}).then(res => {
               if (res.success){
                  // 年龄分布
                  let ageLevelList = res.records.ageLevelList
                  ageLevelList = ageLevelList.map(item => {
                     let name
                     if (item.index === 'young') name = '青年人'
                     if (item.index === 'middle') name = '中年人'
                     if (item.index === 'old') name = '老年人'
                     if (item.index === 'unknown') name = '未知'
                     return { name, value: item.count }
                  })
                  this.pieOptions.series[0].data = ageLevelList
                  this.$echarts.init(document.getElementById('age-pie')).setOption(this.pieOptions);
                  // 控制方式
                  let controlTypeList = res.records.controlTypeList
                  if (controlTypeList.length === 0) return;
                  controlTypeList = controlTypeList.map(item => {
                     let name
                     if (item.index === 'voice') name = '智能语音'
                     if (item.index === 'applets') name = '小程序客控'
                     if (item.index === 'key') name = '按键控制'
                     return {name, value: item.count}
                  })
                  this.pieOptions1.series[0].data = controlTypeList
                  this.$echarts.init(document.getElementById('like-pie')).setOption(this.pieOptions1);
                  // 智控设备使用占比
                  let deviceUseList = res.records.deviceUseList
                  if (deviceUseList.length === 0) return
                  this.barOptions.yAxis.data = []
                  this.barOptions.series[0].data = []
                  deviceUseList.forEach(item => {
                     let name
                     if (item.index === 'HOSW') name = '智能开关'
                     if (item.index === 'HODR') name = '智能窗帘'
                     if (item.index === 'HOSL') name = '智能锁'
                     if (item.index === 'HOIN:8192') name = '电视'
                     if (item.index === 'HOIN:49152') name = '空调'
                     this.barOptions.yAxis.data.push(name)
                     this.barOptions.series[0].data.push(item.count)
                  })
                  this.$echarts.init(document.getElementById('like-bar')).setOption(this.barOptions)
                  // 购物爱好
                  let shoppingHobbyList = res.records.shoppingHobbyList
                  if (shoppingHobbyList.length === 0) return;
                  this.barOptions1.yAxis.data = []
                  this.barOptions1.series[0].data = []
                  shoppingHobbyList.forEach(item => {
                     this.barOptions1.yAxis.data.push(item.index)
                     this.barOptions1.series[0].data.push(item.count)
                  })
                  this.$echarts.init(document.getElementById('shop-like-bar')).setOption(this.barOptions1)
               }
            })
         },
      },
      watch: {
         hotelInfo: {
            handler(newVal, oldVal) {
               if (newVal.id !== oldVal.id && oldVal) {
                  this.getCombinationPortrait()
               }
            },
            deep: true
         }
      },
   }
</script>

<style scoped lang="scss">
   .cont{
      min-width: auto;
   }
   .top{
      color: #222222; margin-bottom: 20px;
      .left-box { padding-right: 10px }
      .right-box{ padding-left: 10px }
      .common-box{
         .chart-box{
            background: #ffffff; box-shadow: 0 0 8px #DCE7F9;
            .title{
               line-height: 50px;
               background: #f7f7f7;
               font-size: 18px;
               height:50px;
               padding:0 0 0 30px;
            }
            .chart-cont{
               padding: 20px;
               .chart{ height: 300px }
            }
         }
      }
   }
</style>
